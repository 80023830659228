import { data, type PartialLocaleInfo } from './data';
import type { BetaLocale, Locale, NonBetaLocale } from './types';

export type LocaleInfo<LOC extends Locale = Locale> = PartialLocaleInfo &
	Readonly<{
		locale: LOC;
	}>;

const localeMap = Object.entries(data.locales).reduce<Partial<Record<Locale, LocaleInfo<Locale>>>>(
	(acc, [locale, info]) => ({
		...acc,
		[locale]: {
			...info,
			locale: locale as Locale,
		},
	}),
	{},
) as Readonly<Record<Locale, LocaleInfo<Locale>>>;

// eslint-disable-next-line no-nested-ternary
const sortFn = (l1: LocaleInfo, l2: LocaleInfo) => (l1.sort < l2.sort ? -1 : l1.sort > l2.sort ? 1 : 0);

const localesInfo: ReadonlyArray<LocaleInfo<Locale>> = Object.values(localeMap).sort(sortFn);
const betaLocalesInfo = localesInfo.filter(({ beta }) => beta) as ReadonlyArray<LocaleInfo<BetaLocale>>;
const nonBetaLocalesInfo = localesInfo.filter(({ beta }) => !beta) as ReadonlyArray<LocaleInfo<NonBetaLocale>>;

const locales: readonly Locale[] = localesInfo.map(({ locale }) => locale);
const betaLocales: readonly BetaLocale[] = betaLocalesInfo.map(({ locale }) => locale);
const nonBetaLocales: readonly NonBetaLocale[] = nonBetaLocalesInfo.map(({ locale }) => locale);

/**
 * Sorted list of locale objects
 */
function getLocalesInfo(filter: 'beta'): ReadonlyArray<LocaleInfo<BetaLocale>>;
function getLocalesInfo(filter: 'nonBeta'): ReadonlyArray<LocaleInfo<NonBetaLocale>>;
function getLocalesInfo(filter?: 'all'): ReadonlyArray<LocaleInfo<Locale>>;
function getLocalesInfo(filter: 'all' | 'beta' | 'nonBeta' = 'all'): ReadonlyArray<LocaleInfo<Locale>> {
	if (filter === 'beta') return betaLocalesInfo;
	if (filter === 'nonBeta') return nonBetaLocalesInfo;
	return localesInfo;
}

/**
 * Sorted list of locales
 */
function getLocales(filter: 'beta'): readonly BetaLocale[];
function getLocales(filter: 'nonBeta'): readonly NonBetaLocale[];
function getLocales(filter?: 'all'): readonly Locale[];
function getLocales(filter: 'all' | 'beta' | 'nonBeta' = 'all'): readonly Locale[] {
	if (filter === 'beta') return betaLocales;
	if (filter === 'nonBeta') return nonBetaLocales;
	return locales;
}

export { getLocalesInfo, getLocales };

export function isSupportedLocale(locale: string): locale is Locale {
	return locale in localeMap;
}
export function getLocaleInfo(locale: Locale): LocaleInfo<Locale> {
	return localeMap[locale];
}
export function isBetaLocale(locale: Locale): locale is BetaLocale {
	return !!localeMap[locale]?.beta;
}
export function getHrefLang(locale: Locale): string {
	return data.hrefLangs[locale];
}

const fallbackLocale = data.fallback;

export function getFallbackLocale(): NonBetaLocale {
	return fallbackLocale;
}
