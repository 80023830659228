import { data } from './data';

type Support = {
	stripe?: boolean;
};

export function getCountryCurrency(country: string): string | undefined {
	// eslint-disable-next-line security/detect-object-injection
	return data.countries[country];
}

/**
 * Returns the currency code for the given country if it is supported by the given payment provider.
 *
 * If no payment provider config is given, it will return the currency code if it is supported by all payment providers.
 *
 * Only Stripe is supported at the moment.
 *
 * @example
 * getSupportedCountryCurrency('US') // returns only if supported by all payment providers
 * getSupportedCountryCurrency('US', { stripe: true }) // returns only if supported by Stripe
 */
export function getSupportedCountryCurrency(country: string, support: Support = { stripe: true }): string | undefined {
	const currency = getCountryCurrency(country);
	if (!currency) return undefined;
	if (support.stripe && data.stripe.unsupported.includes(currency)) {
		return undefined;
	}
	return currency;
}
