import { data } from './data';
import type { Locale } from './types';

/**
 * Get the locale for a given country. If a region is passed, it will try to get the locale for that region if it applies.
 *
 * @example
 * getLocaleFromCountry('CA') // 'en-CA'
 * getLocaleFromCountry('CA', { region: 'QC' }) // 'fr-FR'
 */
export function getLocaleFromCountry(country: string, { region }: { region?: string } = {}): Locale | undefined {
	const mappedLocale = data.countries[country];
	if (!mappedLocale) return undefined;
	if (typeof mappedLocale === 'string') return mappedLocale;
	if (!region) return mappedLocale.default;
	return mappedLocale[region] || mappedLocale.default;
}
