import type { LiteralUnion } from 'type-fest';

import __locales from '../config/locales.json';

import type { Lang, Locale, NonBetaLocale } from './types';

export type PartialLocaleInfo = Readonly<{
	display: string;
	sort: string;
	beta: boolean;
}>;

export type LocalesFromJson = Readonly<{
	locales: Readonly<Record<Locale, PartialLocaleInfo>>;
	countries: Readonly<Record<string, Locale | Readonly<Record<LiteralUnion<'default', string>, Locale>>>>;
	langs: Readonly<Record<Lang, Locale | Readonly<Record<LiteralUnion<'default', string>, Locale>>>>;
	hrefLangs: Readonly<Record<Locale, string>>;
	fallback: NonBetaLocale;
}>;

export const data = __locales as LocalesFromJson;
