export type PerimeterXParam =
	| 'petitionId'
	| 'userId'
	| 'emailDomain'
	| 'emailHash'
	| 'paymentDeclineCode' // for AccountDefender only
	| 'accountDate'
	| 'countryCode';

// naming convention for perimeterX custom param
// window._pxParam1, window._pxParam2 etc up to a maximum of 10.
type PerimeterXParamIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type PerimeterXParamVariableName = `_pxParam${PerimeterXParamIndex}`;

const MAP: Record<PerimeterXParam, PerimeterXParamIndex> = {
	petitionId: 1,
	userId: 2,
	emailDomain: 3,
	emailHash: 4,
	paymentDeclineCode: 5,
	accountDate: 6,
	countryCode: 7,
};

export function getPerimeterXParamVariableName(param: PerimeterXParam): PerimeterXParamVariableName {
	// eslint-disable-next-line security/detect-object-injection
	return `_pxParam${MAP[param]}`;
}
