/* eslint-disable security/detect-object-injection */
import type { Locale } from '@change-corgi/config/locales';

import { data } from './data';

export function getFacebookVanity({ locale, countryCode }: { locale?: Locale; countryCode: string }): string {
	const countryConfig = data.vanity[countryCode];
	if (!countryConfig) {
		return data.defaultVanity;
	}
	if (typeof countryConfig === 'string') {
		return countryConfig;
	}
	if (!locale || !countryConfig.localesOverrides || !countryConfig.localesOverrides[locale]) {
		return countryConfig.vanity;
	}
	return countryConfig.localesOverrides[locale];
}
