import { data } from './data';
import type { Lang, Locale } from './types';

/**
 * Get a locale (xx-YY) from a lang (xx) and a country code
 *
 * @example
 * getLocaleFromLang('en', { countryCode: 'US' }) // 'en-US'
 * getLocaleFromLang('en', { countryCode: 'CA' }) // 'en-CA'
 * getLocaleFromLang('es', { countryCode: 'ES' }) // 'es-ES'
 * getLocaleFromLang('es', { countryCode: 'CO' }) // 'es-419'
 */
export function getLocaleFromLang(lang: Lang, { countryCode }: { countryCode: string }): Locale {
	const mappedLocale = data.langs[lang];
	if (typeof mappedLocale === 'string') return mappedLocale;
	return mappedLocale[countryCode] || mappedLocale.default;
}
