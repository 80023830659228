import __json from '../config/currency.json';

type DataFromJson = {
	countries: Readonly<Record<string, string>>;
	stripe: {
		unsupported: readonly string[];
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
export const data: DataFromJson = __json as any;
