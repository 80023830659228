import type { Locale } from '@change-corgi/config/locales';

import { data } from './data';

export function getFacebookLocale(locale: Locale): string {
	// eslint-disable-next-line security/detect-object-injection
	return data.locales[locale];
}

export function getLocaleFromFacebookLocale(facebookLocale: string): Locale | undefined {
	// eslint-disable-next-line security/detect-object-injection
	return data.localesReverse[facebookLocale];
}
