import type { BetaLocale, Locale, NonBetaLocale } from '@change-corgi/config/locales';

import { routes, type Routes } from './data';

function getRoutes(locale: NonBetaLocale): Routes;
function getRoutes(locale: BetaLocale): undefined;
function getRoutes(locale: Locale): Routes | undefined;
function getRoutes(locale: Locale): Routes | undefined {
	return routes[locale as NonBetaLocale];
}

export { getRoutes };
export type { Routes };
