import type { SignatureAddressGranularity } from './data';
import { getSignatureAddressGranularity } from './getSignatureAddressGranularity';

type Field = 'postal_code' | 'city';
const FIELD_MAP: Record<Field, readonly SignatureAddressGranularity[]> = {
	postal_code: ['postal_code', 'city_and_postal_code'],
	city: ['city_and_postal_code'],
};

export function isSignatureAddressFieldRequired(countryCode: string, type: Field): boolean {
	const granularity = getSignatureAddressGranularity(countryCode);
	if (!granularity) return false;
	// eslint-disable-next-line security/detect-object-injection
	return FIELD_MAP[type].includes(granularity);
}
