import { data } from './data';

export type NameOrder = 'last_first' | 'first_last';

/**
 * Returns whether the last name comes before the first name in this country
 */
export function getNameOrder(countryCode: string): NameOrder {
	return data.nameOrderLastFirst.includes(countryCode) ? 'last_first' : 'first_last';
}

/**
 * Returns the first and last name in the order they should be displayed in this country
 *
 * @example
 * getByNameOrder('JP', { first: '太郎', last: '山田' }).join(' '); // => '山田 太郎'
 * getByNameOrder('US', { first: <FirstName />, last: <LastName /> });
 */
export function getByNameOrder<T>(countryCode: string, { first, last }: { first: T; last: T }): readonly [T, T] {
	return getNameOrder(countryCode) === 'last_first' ? [last, first] : [first, last];
}
