export type { LocaleInfo } from './locales';
export {
	getFallbackLocale,
	getLocaleInfo,
	getLocales,
	getLocalesInfo,
	getHrefLang,
	isBetaLocale,
	isSupportedLocale,
} from './locales';
export { isSupportedLang } from './langs';
export { getLocaleFromCountry } from './fromCountry';
export { getLocaleFromLang } from './fromLang';
export type { BetaLocale, Locale, NonBetaLocale, Lang } from './types';
