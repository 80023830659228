var v1: any = 'https://help.change.org/s/error';
var v2: any = '/careers';
var v3: any = '/about/team';
var v4: any = '/about';
var v5: any = '/impact';
const obj: any = {
	'en-US': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				connect: 'mailto:press@change.org',
				guides: '/petition-guides',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=en_US',
				team: v3,
				create_petition: '/petition-guides/create-a-petition',
				share_petition: '/petition-guides/share-your-petition',
				build_momentum: '/petition-guides/build-momentum',
				reach_media: '/petition-guides/media',
				engage_decision_maker: '/petition-guides/engage-decision-makers',
				declare_victory: '/petition-guides/declare-petition-victory',
				how_petition_works: 'https://guide.change.org',
				start_online_petition: 'https://guide.change.org',
				promote_your_petition: 'https://guide.change.org',
				talk_to_decision_maker: 'https://guide.change.org',
				advanced_tips: 'https://guide.change.org',
				grow_support_base: 'https://guide.change.org',
				get_story_to_media: 'https://guide.change.org',
				build_media_conversation: 'https://guide.change.org',
				deliver_in_person: 'https://guide.change.org',
				organize_call_in_day: 'https://guide.change.org'
			}
		}
	},
	'en-AU': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				connect: 'mailto:press.australia@change.org',
				guides: 'https://guide.change.org/home_aus',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=en_US',
				team: v3,
				create_petition: 'https://guide.change.org/create_aus',
				share_petition: 'https://guide.change.org/share_aus',
				build_momentum: 'https://guide.change.org/build-momentum_aus',
				reach_media: 'https://guide.change.org/media-outreach_aus',
				engage_decision_maker: 'https://guide.change.org/engage_aus',
				declare_victory: 'https://guide.change.org/declare-victory_aus',
				how_petition_works: 'https://guide.change.org',
				start_online_petition: 'https://guide.change.org',
				promote_your_petition: 'https://guide.change.org',
				talk_to_decision_maker: 'https://guide.change.org',
				advanced_tips: 'https://guide.change.org',
				grow_support_base: 'https://guide.change.org',
				get_story_to_media: 'https://guide.change.org',
				build_media_conversation: 'https://guide.change.org',
				deliver_in_person: 'https://guide.change.org',
				organize_call_in_day: 'https://guide.change.org'
			}
		}
	},
	'en-CA': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				connect: null,
				guides: '/petition-guides',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=en_US',
				team: v3,
				create_petition: '/petition-guides/create-a-petition',
				share_petition: '/petition-guides/share-your-petition',
				build_momentum: '/petition-guides/build-momentum',
				reach_media: '/petition-guides/media',
				engage_decision_maker: '/petition-guides/engage-decision-makers',
				declare_victory: '/petition-guides/declare-petition-victory',
				how_petition_works: 'https://guide.change.org',
				start_online_petition: 'https://guide.change.org',
				promote_your_petition: 'https://guide.change.org',
				talk_to_decision_maker: 'https://guide.change.org',
				advanced_tips: 'https://guide.change.org',
				grow_support_base: 'https://guide.change.org',
				get_story_to_media: 'https://guide.change.org',
				build_media_conversation: 'https://guide.change.org',
				deliver_in_person: 'https://guide.change.org',
				organize_call_in_day: 'https://guide.change.org'
			}
		}
	},
	'en-GB': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				connect: 'mailto:connect.uk@change.org',
				guides: 'https://guide.change.org/home-uk',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=en_US',
				team: v3,
				create_petition: 'https://guide.change.org/create-uk',
				share_petition: 'https://guide.change.org/share-uk',
				build_momentum: 'https://guide.change.org/buildmomentum-uk',
				reach_media: 'https://guide.change.org/media-uk',
				engage_decision_maker: 'https://guide.change.org/decision-makers-uk',
				declare_victory: 'https://guide.change.org/declare-victory-uk',
				how_petition_works: 'https://guide.change.org/home-uk',
				start_online_petition: 'https://guide.change.org/home-uk',
				promote_your_petition: 'https://guide.change.org/home-uk',
				talk_to_decision_maker: 'https://guide.change.org/home-uk',
				advanced_tips: 'https://guide.change.org/home-uk',
				grow_support_base: 'https://guide.change.org/home-uk',
				get_story_to_media: 'https://guide.change.org/home-uk',
				build_media_conversation: 'https://guide.change.org/home-uk',
				deliver_in_person: 'https://guide.change.org/home-uk',
				organize_call_in_day: 'https://guide.change.org/home-uk'
			}
		}
	},
	'en-IN': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				connect: null,
				guides: '/petition-guides',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=en_US',
				team: v3,
				create_petition: '/petition-guides/create-a-petition',
				share_petition: '/petition-guides/share-your-petition',
				build_momentum: '/petition-guides/build-momentum',
				reach_media: '/petition-guides/media',
				engage_decision_maker: '/petition-guides/engage-decision-makers',
				declare_victory: '/petition-guides/declare-petition-victory',
				how_petition_works: 'https://guide.change.org',
				start_online_petition: 'https://guide.change.org',
				promote_your_petition: 'https://guide.change.org',
				talk_to_decision_maker: 'https://guide.change.org',
				advanced_tips: 'https://guide.change.org',
				grow_support_base: 'https://guide.change.org',
				get_story_to_media: 'https://guide.change.org',
				build_media_conversation: 'https://guide.change.org',
				deliver_in_person: 'https://guide.change.org',
				organize_call_in_day: 'https://guide.change.org'
			}
		}
	},
	'de-DE': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: 'https://careers.change.org/',
				guides: '/l/de/p/kampagnentraining',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=de',
				team: v3,
				create_petition: '/l/de/p/petition-starten',
				share_petition: '/l/de/p/kampagne-verbreiten',
				build_momentum: '/l/de/p/erfolg-ausbauen',
				reach_media: '/l/de/p/massenmedien-nutzen',
				engage_decision_maker: '/l/de/p/entscheidungstraegerinnen-kontaktieren',
				declare_victory: '/l/de/p/erfolg-erklaeren',
				how_petition_works: '/l/de/p/kampagnentraining',
				start_online_petition: '/l/de/p/kampagnentraining',
				promote_your_petition: '/l/de/p/kampagnentraining',
				talk_to_decision_maker: '/l/de/p/kampagnentraining',
				advanced_tips: '/l/de/p/kampagnentraining',
				grow_support_base: '/l/de/p/kampagnentraining',
				get_story_to_media: '/l/de/p/kampagnentraining',
				build_media_conversation: '/l/de/p/kampagnentraining',
				deliver_in_person: '/l/de/p/kampagnentraining',
				organize_call_in_day: '/l/de/p/kampagnentraining'
			}
		}
	},
	'es-ES': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				guides: 'https://guide.change.org/comousarchange',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=es',
				team: v3,
				create_petition: 'https://guide.change.org/iniciatupeticion',
				share_petition: 'https://guide.change.org/difundetupeticion',
				build_momentum: 'https://guide.change.org/hazruidocontucampana',
				reach_media: 'https://guide.change.org/llegaalosmediosdecomunicacion',
				engage_decision_maker: 'https://guide.change.org/entregalasfirmas',
				declare_victory: 'https://guide.change.org/declaralavictoria',
				how_petition_works: '/http://www.comousarchange.org',
				start_online_petition: 'http://www.comousarchange.org',
				promote_your_petition: 'http://www.comousarchange.org',
				talk_to_decision_maker: 'http://www.comousarchange.org',
				advanced_tips: 'http://www.comousarchange.org',
				grow_support_base: 'http://www.comousarchange.org',
				get_story_to_media: 'http://www.comousarchange.org',
				build_media_conversation: 'http://www.comousarchange.org',
				deliver_in_person: 'http://www.comousarchange.org',
				organize_call_in_day: 'http://www.comousarchange.org'
			}
		}
	},
	'es-419': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				guides: 'http://www.comousarchange.org',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=es',
				team: v3,
				create_petition: 'http://www.comousarchange.org/primerospasos',
				share_petition: 'http://www.comousarchange.org/compartir',
				build_momentum: 'http://www.comousarchange.org/ruido',
				reach_media: 'http://www.comousarchange.org/medios',
				engage_decision_maker: 'http://www.comousarchange.org/destinatario',
				declare_victory: 'http://www.comousarchange.org/victoria/',
				how_petition_works: '/http://www.comousarchange.org',
				start_online_petition: 'http://www.comousarchange.org',
				promote_your_petition: 'http://www.comousarchange.org',
				talk_to_decision_maker: 'http://www.comousarchange.org',
				advanced_tips: 'http://www.comousarchange.org',
				grow_support_base: 'http://www.comousarchange.org',
				get_story_to_media: 'http://www.comousarchange.org',
				build_media_conversation: 'http://www.comousarchange.org',
				deliver_in_person: 'http://www.comousarchange.org',
				organize_call_in_day: 'http://www.comousarchange.org'
			}
		}
	},
	'es-AR': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				guides: '/l/ar',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=es',
				team: v3,
				create_petition: 'http://www.comousarchange.org/primerospasos',
				share_petition: 'http://www.comousarchange.org/compartir',
				build_momentum: 'http://www.comousarchange.org/ruido',
				reach_media: 'http://www.comousarchange.org/medios',
				engage_decision_maker: 'http://www.comousarchange.org/destinatario',
				declare_victory: 'http://www.comousarchange.org/victoria',
				how_petition_works: '/http://www.comousarchange.org',
				start_online_petition: 'http://www.comousarchange.org',
				promote_your_petition: 'http://www.comousarchange.org',
				talk_to_decision_maker: 'http://www.comousarchange.org',
				advanced_tips: 'http://www.comousarchange.org',
				grow_support_base: 'http://www.comousarchange.org',
				get_story_to_media: 'http://www.comousarchange.org',
				build_media_conversation: 'http://www.comousarchange.org',
				deliver_in_person: 'http://www.comousarchange.org',
				organize_call_in_day: 'http://www.comousarchange.org'
			}
		}
	},
	'fr-FR': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				guides: 'https://guide.change.org/accueil',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=fr',
				team: v3,
				create_petition: 'https://guide.change.org/lancez',
				share_petition: 'https://guide.change.org/partagez',
				build_momentum: 'https://guide.change.org/mobilisez',
				reach_media: 'https://guide.change.org/mediatisez',
				engage_decision_maker: 'https://guide.change.org/dialoguez',
				declare_victory: 'https://guide.change.org/celebrez',
				how_petition_works: 'https://guide.change.org/accueil',
				start_online_petition: 'https://guide.change.org/accueil',
				promote_your_petition: 'https://guide.change.org/accueil',
				talk_to_decision_maker: 'https://guide.change.org/accueil',
				advanced_tips: 'https://guide.change.org/accueil',
				grow_support_base: 'https://guide.change.org/accueil',
				get_story_to_media: 'https://guide.change.org/accueil',
				build_media_conversation: 'https://guide.change.org/accueil',
				deliver_in_person: 'https://guide.change.org/accueil',
				organize_call_in_day: 'https://guide.change.org/accueil'
			}
		}
	},
	'hi-IN': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				connect: 'mailto:press@change.org',
				guides: 'https://help.change.org/s/error-hi',
				impact: v5,
				promoted_petitions: v1,
				team: v3,
				create_petition: '/petition-guides/create-a-petition',
				share_petition: '/petition-guides/share-your-petition',
				build_momentum: '/petition-guides/build-momentum',
				reach_media: '/petition-guides/media',
				engage_decision_maker: '/petition-guides/engage-decision-makers',
				declare_victory: '/petition-guides/declare-petition-victory',
				how_petition_works: v1,
				start_online_petition: v1,
				promote_your_petition: v1,
				talk_to_decision_maker: v1,
				advanced_tips: v1,
				grow_support_base: v1,
				get_story_to_media: v1,
				build_media_conversation: v1,
				deliver_in_person: v1,
				organize_call_in_day: v1
			}
		}
	},
	'id-ID': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				connect: 'mailto:press@change.org',
				guides: 'https://help.change.org/s/error-id',
				impact: v5,
				promoted_petitions: v1,
				team: v3,
				create_petition: '/petition-guides/create-a-petition',
				share_petition: '/petition-guides/share-your-petition',
				build_momentum: '/petition-guides/build-momentum',
				reach_media: '/petition-guides/media',
				engage_decision_maker: '/petition-guides/engage-decision-makers',
				declare_victory: '/petition-guides/declare-petition-victory',
				how_petition_works: v1,
				start_online_petition: v1,
				promote_your_petition: v1,
				talk_to_decision_maker: v1,
				advanced_tips: v1,
				grow_support_base: v1,
				get_story_to_media: v1,
				build_media_conversation: v1,
				deliver_in_person: v1,
				organize_call_in_day: v1
			}
		}
	},
	'it-IT': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				guides: 'https://guide.change.org/home-italia',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=it',
				team: v3,
				create_petition: 'https://guide.change.org/crea',
				share_petition: 'https://guide.change.org/condividi',
				build_momentum: 'https://guide.change.org/construisci',
				reach_media: 'https://guide.change.org/media-italia',
				engage_decision_maker: 'https://guide.change.org/decisore',
				declare_victory: 'https://guide.change.org/vittoria',
				how_petition_works: 'https://guide.change.org/home-italia',
				start_online_petition: 'https://guide.change.org/home-italia',
				promote_your_petition: 'https://guide.change.org/home-italia',
				talk_to_decision_maker: 'https://guide.change.org/home-italia',
				advanced_tips: 'https://guide.change.org/home-italia',
				grow_support_base: 'https://guide.change.org/home-italia',
				get_story_to_media: 'https://guide.change.org/home-italia',
				build_media_conversation: 'https://guide.change.org/home-italia',
				deliver_in_person: 'https://guide.change.org/home-italia',
				organize_call_in_day: 'https://guide.change.org/home-italia'
			}
		}
	},
	'ja-JP': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				guides: '/l/jp/guide00-home',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=ja',
				team: v3,
				create_petition: '/l/jp/guide01',
				share_petition: '/l/jp/guide02',
				build_momentum: '/l/jp/guide03',
				reach_media: '/l/jp/guide04',
				engage_decision_maker: '/l/jp/guide05',
				declare_victory: '/l/jp/guide07',
				how_petition_works: '/l/jp/guide00-home',
				start_online_petition: '/l/jp/guide00-home',
				promote_your_petition: '/l/jp/guide00-home',
				talk_to_decision_maker: '/l/jp/guide00-home',
				advanced_tips: '/l/jp/guide00-home',
				grow_support_base: '/l/jp/guide00-home',
				get_story_to_media: '/l/jp/guide00-home',
				build_media_conversation: '/l/jp/guide00-home',
				deliver_in_person: '/l/jp/guide00-home',
				organize_call_in_day: '/l/jp/guide00-home'
			}
		}
	},
	'pt-BR': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				guides: 'http://www.comousarachange.org/',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=pt_BR',
				team: v3,
				create_petition: 'http://www.comousarachange.org/como-fazer-abaixo-assinado',
				share_petition: 'http://www.comousarachange.org/como-espalhar',
				build_momentum: 'http://www.comousarachange.org/como-fazer-barulho',
				reach_media: 'http://www.comousarachange.org/como-sair-na-midia',
				engage_decision_maker: 'http://www.comousarachange.org/como-falar-com-quem-decide',
				declare_victory: 'http://www.comousarachange.org/como-declarar-vitoria',
				how_petition_works: 'http://www.comousarachange.org',
				start_online_petition: 'http://www.comousarachange.org',
				promote_your_petition: 'http://www.comousarachange.org',
				talk_to_decision_maker: 'http://www.comousarachange.org',
				advanced_tips: 'http://www.comousarachange.org',
				grow_support_base: 'http://www.comousarachange.org',
				get_story_to_media: 'http://www.comousarachange.org',
				build_media_conversation: 'http://www.comousarachange.org',
				deliver_in_person: 'http://www.comousarachange.org',
				organize_call_in_day: 'http://www.comousarachange.org'
			}
		}
	},
	'ru-RU': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				guides: 'https://russianguidechange.org/516212259308',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=ru',
				team: v3,
				create_petition: 'https://russianguidechange.org/startapetition',
				share_petition: 'https://russianguidechange.org/422150326827',
				build_momentum: 'https://russianguidechange.org/532354153253',
				reach_media: 'https://russianguidechange.org/516212259308',
				engage_decision_maker: 'https://russianguidechange.org/733073910238',
				declare_victory: 'https://russianguidechange.org/202304372910',
				how_petition_works: 'https://russianguidechange.org',
				start_online_petition: 'https://russianguidechange.org',
				promote_your_petition: 'https://russianguidechange.org',
				talk_to_decision_maker: 'https://russianguidechange.org',
				advanced_tips: 'https://russianguidechange.org',
				grow_support_base: 'https://russianguidechange.org',
				get_story_to_media: 'https://russianguidechange.org',
				build_media_conversation: 'https://russianguidechange.org',
				deliver_in_person: 'https://russianguidechange.org',
				organize_call_in_day: 'https://russianguidechange.org'
			}
		}
	},
	'th-TH': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				connect: 'mailto:press@change.org',
				guides: 'https://help.change.org/s/error-th',
				impact: v5,
				promoted_petitions: v1,
				team: v3,
				create_petition: '/petition-guides/create-a-petition',
				share_petition: '/petition-guides/share-your-petition',
				build_momentum: '/petition-guides/build-momentum',
				reach_media: '/petition-guides/media',
				engage_decision_maker: '/petition-guides/engage-decision-makers',
				declare_victory: '/petition-guides/declare-petition-victory',
				how_petition_works: v1,
				start_online_petition: v1,
				promote_your_petition: v1,
				talk_to_decision_maker: v1,
				advanced_tips: v1,
				grow_support_base: v1,
				get_story_to_media: v1,
				build_media_conversation: v1,
				deliver_in_person: v1,
				organize_call_in_day: v1
			}
		}
	},
	'tr-TR': {
		fe: {
			routes: {
				about: v4,
				advertise: v1,
				careers: v2,
				connect: 'mailto:press@change.org',
				guides: 'https://help.change.org/s/topic/0TO0G0000000bhgWAA/tips-and-guides?language=tr',
				impact: v5,
				promoted_petitions: 'https://help.change.org/s/article/Promoted-Petitions-FAQs?language=tr',
				team: v3,
				create_petition: '/petition-guides/create-a-petition',
				share_petition: '/petition-guides/share-your-petition',
				build_momentum: '/petition-guides/build-momentum',
				reach_media: '/petition-guides/media',
				engage_decision_maker: '/petition-guides/engage-decision-makers',
				declare_victory: '/petition-guides/declare-petition-victory',
				how_petition_works: v1,
				start_online_petition: v1,
				promote_your_petition: v1,
				talk_to_decision_maker: v1,
				advanced_tips: v1,
				grow_support_base: v1,
				get_story_to_media: v1,
				build_media_conversation: v1,
				deliver_in_person: v1,
				organize_call_in_day: v1
			}
		}
	}
};
export default obj;
