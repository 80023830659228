import __json from '../config/signatures.json';

export type SignatureAddressGranularity = 'postal_code' | 'city_and_postal_code';

type DataFromJson = {
	addressGranularity: Readonly<Record<string, SignatureAddressGranularity>>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
export const data: DataFromJson = __json as any;
