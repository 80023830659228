import type { SubEnvironment } from '@change-corgi/config/environments';
import type { Locale } from '@change-corgi/config/locales';

import __json from '../config/facebook.json';

type CountryData =
	| string
	| Readonly<{
			vanity: string;
			localesOverrides?: Readonly<Record<Locale, string>>;
	  }>;

type DataFromJson = Readonly<{
	appId: Readonly<Record<SubEnvironment, string>>;
	namespace: Readonly<Record<SubEnvironment, string>>;
	vanity: Readonly<Record<string, CountryData>>;
	defaultVanity: string;
	locales: Readonly<Record<Locale, string>>;
	localesReverse: Readonly<Record<string, Locale>>;
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
export const data: DataFromJson = __json as any;

export const facebookAppIds = data.appId;
export const facebookNamespaces = data.namespace;
